import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import Setting from "./Pages/admin/Setting";

const Home = React.lazy(() => import('./Pages/Home/Home'));
const ProductDetail = React.lazy(() => import('./Pages/ProductDetail/ProductDetail'));
const NotFoundPage = React.lazy(() => import('./Pages/NotFoundPage/NotFoundPage'));
const Address = React.lazy(() => import('./Pages/Address/Address'));
const OrderSummary = React.lazy(() => import('./Pages/OrderSummary/OrderSummary'));
const Payment = React.lazy(() => import('./Pages/Payment/Payment'));
const Login = React.lazy(() => import('./Pages/admin/Login'));
const Dashboard = React.lazy(() => import('./Pages/admin/Dashboard'));
const Products = React.lazy(() => import('./Pages/admin/Products'));

const App = () => {
    const isAuthenticated = !!localStorage.getItem('token');
    return (
        <BrowserRouter>
            <div>
                <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><Spinner animation="border" variant="primary" /></div>}>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/product-details/:id' element={<ProductDetail />} />
                        <Route path="/address" element={<Address />} />
                        <Route path="/order-summary" element={<OrderSummary />} />
                        <Route path='/payment' element={<Payment />} />
                        
                        <Route path="/admin/login" element={<Login />} />
                        <Route path='/admin/dashboard' element={isAuthenticated ? <Dashboard /> : <Navigate to="/admin/login" replace />} />
                        <Route path='/admin/products' element={isAuthenticated ? <Products /> : <Navigate to="/admin/login" replace />} />
                        <Route path="/admin/settings" element={isAuthenticated ? <Setting /> : <Navigate to="/admin/login" replace />} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </Suspense>
            </div>
        </BrowserRouter>
    )
}

export default App;
