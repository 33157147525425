import { Link, useNavigate } from "react-router-dom"
import avatar1 from "../../assets/admin/images/users/avatar-1.jpg"
import logo from "../../assets/admin/images/logo.png"
import logoSm from "../../assets/admin/images/logo-sm.png"
import logoDark from "../../assets/admin/images/logo-dark.png"
import logoDarkSm from "../../assets/admin/images/logo-dark-sm.png"
import { Dropdown } from "react-bootstrap"
const TopMenu = () => {
    const navigate = useNavigate()
    return (
        <div className="navbar-custom topnav-navbar">
				<div className="container-fluid detached-nav">
					<div className="logo-topbar">
						<Link to={'/admin/dashboard'} className="logo-light">
							<span className="logo-lg">
								<img src={logo} alt="logo" height="22" />
							</span>
							<span className="logo-sm">
								<img src={logoSm} alt="small logo" height="22" />
							</span>
						</Link>

						<Link to={'/admin/dashboard'} className="logo-dark">
							<span className="logo-lg">
								<img src={logoDark} alt="dark logo" height="22" />
							</span>
							<span className="logo-sm">
								<img src={logoDarkSm} alt="small logo" height="22" />
							</span>
						</Link>
					</div>

					<button className="button-toggle-menu">
						<i className="mdi mdi-menu"></i>
					</button>
					<button className="navbar-toggle" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
						<div className="lines">
							<span></span>
							<span></span>
							<span></span>
						</div>
					</button>

					<ul className="list-unstyled topbar-menu float-end mb-0">
						<li className="notification-list d-none d-sm-inline-block">
							<a className="nav-link" data-bs-toggle="offcanvas" href="#theme-settings-offcanvas">
								<i className="ri-settings-3-line noti-icon"></i>
							</a>
						</li>

						<li className="notification-list d-none d-sm-inline-block">
							<a className="nav-link" href="javascript:void(0)" id="light-dark-mode">
								<i className="ri-moon-line noti-icon"></i>
							</a>
						</li>

						<li className="notification-list d-none d-md-inline-block">
							<a className="nav-link" href="#" data-toggle="fullscreen">
								<i className="ri-fullscreen-line noti-icon"></i>
							</a>
						</li>

						<li className="dropdown notification-list">
                            <Dropdown className="nav-link dropdown-toggle nav-user arrow-none me-0">
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    <span className="account-user-avatar">
                                        <img src={avatar1} alt="user-image" className="rounded-circle" />
                                    </span>
                                    <span>
                                        <span className="account-user-name" style={{marginTop: '0.5rem'}}>Admin</span>
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <i className="mdi mdi-account-circle me-1"></i>
                                        <span>My Account</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item 
                                        onClick={() => {
                                            localStorage.clear()
                                            navigate("/admin/login")
                                        }}
                                    >
                                        <i className="mdi mdi-logout me-1"></i>
                                        <span>Logout</span>
                                    </Dropdown.Item>
                                    {/* You can add more Dropdown.Item components for additional options */}
                                </Dropdown.Menu>
                            </Dropdown>
							
						</li>
					</ul>
				</div>
			</div>
    )
}

export default TopMenu