import { Link } from "react-router-dom"
import logo from "../../assets/admin/images/logo.png"
import logoSm from "../../assets/admin/images/logo-sm.png"
import logoDark from "../../assets/admin/images/logo-dark.png"
import logoDarkSm from "../../assets/admin/images/logo-dark-sm.png"

import "../../assets/admin/css/custom.css"
import "../../assets/admin/css/daterangepicker.css"
import "../../assets/admin/css/pnotify.custom.css"
import "../../assets/admin/css/dataTables.bootstrap5.min.css"
import "../../assets/admin/css/select2.min.css"
import "../../assets/admin/css/app-saas.min.css"
import "../../assets/admin/css/icons.min.css"

const LeftMenu = () => {
    return (
        <div className="leftside-menu">
                <Link to={'/admin/dashboard'} className="logo logo-light">
                    <span className="logo-lg">
                        <img src={logo} alt="logo" height="60" />
                    </span>
                    <span className="logo-sm">
                        <img src={logoSm} alt="small logo" height="22" />
                    </span>
                </Link>
                <Link to={'/admin/dashboard'} className="logo logo-dark">
                    <span className="logo-lg">
                        <img src={logoDark} alt="dark logo" height="60" />
                    </span>
                    <span className="logo-sm">
                        <img src={logoDarkSm} alt="small logo" height="22" />
                    </span>
                </Link>
                <button type="button" className="btn button-sm-hover p-0" data-bs-toggle="tooltip" data-bs-placement="right" title="Show Full Sidebar">
                    <i className="ri-checkbox-blank-circle-line align-middle"></i>
                </button>

                <div className="h-100" id="leftside-menu-container" data-simplebar>
                    <ul className="side-nav">

                        <li className="side-nav-title side-nav-item" style={{ fontSize: '16px' }}>Dashboard</li>

                        <li className="side-nav-item">
                            <Link to={'/admin/dashboard'} className="side-nav-link">
                                <i className="uil-home-alt"></i>
                                <span  style={{ fontSize: '16px' }}> Dashboard </span>
                            </Link>
                        </li>

                        <li className="side-nav-item">
                            <Link to={'/admin/products'} className="side-nav-link">
                                <i className="uil-box"></i>
                                <span  style={{ fontSize: '16px' }}> Products </span>
                            </Link>
                        </li>
                        <li className="side-nav-item">
                            <Link to={'/admin/settings'} href="manage_setting.php" className="side-nav-link">
                                <i className="ri-settings-5-line"></i>
                                <span  style={{ fontSize: '16px' }}> Settings </span>
                            </Link>
                        </li>

                    </ul>

                    <div className="clearfix"></div>
                </div>
            </div>
    )
}

export default LeftMenu