import { useEffect, useState } from "react"
import LeftMenu from "./LeftMenu"
import TopMenu from "./TopMenu"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const Setting = () => {
    const [settingId, setSettingId] = useState("")
    const [showGpay, setShowGpay] = useState(false)
    const [password, setPassword] = useState("")
    const [isVisible, setIsVisible] = useState(false)
    const [upiId, setUpiId] = useState("")
    const [pixelCode, setPixelCode] = useState("")
    const navigate = useNavigate()
    useEffect(() => {
        getSettings()
    }, [])
    const getSettings = async () => {
        try {
            const headers = {
                'x-authorization': `${localStorage.getItem("token")}` // Example authorization header
            };
            let response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/setting`, {headers})
            console.log(response.data.data, "====response?.data?.data?.upiId");
            if(response && response.status == 200 && response?.data?.code == 200 && response.data.data) {
                setSettingId(response?.data?.data?._id)
                setShowGpay(response?.data?.data?.showGpay)
                setPassword(response?.data?.data?.password)
                setUpiId(response?.data?.data?.upiId)
                setPixelCode(response?.data?.data?.pixelCode)
            } else if(response && response.status == 401 ) {
                localStorage.clear()
                navigate("/admin/login")
            } else {
                toast.error(response?.message ?? response?.data?.message ?? "Something went wrong, Please try again!")
            }
        } catch (error) {
            if(error && error.response && error.response.status == 401) {
                localStorage.clear()
                navigate("/admin/login")
            }
        }
    }
    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            const headers = {
                'x-authorization': `${localStorage.getItem("token")}` // Example authorization header
            };
            console.log({password, upiId, pixelCode});
            if(password && upiId && pixelCode) {
                let url = `${process.env.REACT_APP_ENDPOINT}/setting`
                let obj = {
                    isGpay: showGpay,
                    password: password,
                    upiId: upiId,
                    pixelCode: pixelCode
                }
                if(settingId) {
                    obj.id = settingId
                }
                let response = await axios.post(url, obj, {headers})
                if(response && response.status == 200 && response?.data?.code == 200) {
                    console.log(response.data);
                    toast.success("Setting save Successfully!")
                    getSettings()
                    
                } else if(response && response.status == 401 ) {
                    localStorage.clear()
                    navigate("/admin/login")
                } else {
                    toast.error(response?.message ?? response?.data?.message ?? "Something went wrong, Please try again!")
                }
            } else {
                toast.error("Please enter all details")
            }
        } catch(error) {
            if(error && error.response && error.response.status == 401) {
                localStorage.clear()
                navigate("/admin/login")
            }
        }
    }
    return (
        <>
            <div className="wrapper">
                <TopMenu />
                <LeftMenu />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box">
                                        <h4 className="page-title" style={{ textAlign: 'left' }}>Settings</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        <div className="card-body">

                                            <div id="formDiv">
                                                <form className="needs-validation" method="POST" novalidate onSubmit={handleSubmit}>
                                                    {/* <input type="hidden" id="id" /> */}
                                                    {/* <div className="row d-none">
                                                        <div className="mb-3 offset-sm-2 col-sm-4">
                                                            <label className="form-label" for="source">Company Name</label>
                                                            <input type="text" className="form-control" id="cmp_name" placeholder="Company Name" required />
                                                            <div className="invalid-feedback"> Please enter company name. </div>
                                                        </div>
                                                        <div className="mb-3 col-sm-4">
                                                            <label className="form-label" for="cmp_email">Company Email</label>
                                                            <input type="email" className="form-control" id="cmp_email" placeholder="Company Email" autocomplete="off" required />
                                                            <div className="invalid-feedback"> Please enter email. </div>
                                                        </div>
                                                    </div>
                                                    <div className="row d-none">
                                                        <div className="mb-3 offset-sm-2 col-sm-4">
                                                            <label className="form-label" for="admin_email">Admin Email</label>
                                                            <input type="email" className="form-control" id="admin_email" placeholder="Admin Email" required />
                                                            <div className="invalid-feedback"> Please enter email. </div>
                                                        </div>
                                                        <div className="mb-3 col-sm-4">
                                                            <label className="form-label" for="admin_email_password">Admin Email Password</label>
                                                            <div className="input-group input-group-merge">
                                                                <input type="password" id="admin_email_password" className="form-control" required="" placeholder="Password" />
                                                                <div className="input-group-text" data-password="false">
                                                                    <span className="password-eye"></span>
                                                                </div>
                                                            </div>
                                                            <div className="invalid-feedback"> Please enter password. </div>
                                                        </div>
                                                    </div>
                                                    <div className="row d-none">
                                                        <div className="mb-3 offset-sm-2 col-sm-4">
                                                            <label className="form-label" for="contact1">Contact 1</label>
                                                            <input type="text" className="form-control" id="contact1" placeholder="Contact" required />
                                                            <div className="invalid-feedback"> Please enter Contact. </div>
                                                        </div>
                                                        <div className="mb-3 col-sm-4">
                                                            <label className="form-label" for="contact2">Contact 2</label>
                                                            <input type="text" className="form-control" id="contact2" placeholder="Contact" required />
                                                            <div className="invalid-feedback"> Please enter Contact. </div>
                                                        </div>
                                                    </div>
                                                    <div className="row d-none">
                                                        <div className="mb-3 offset-sm-2 col-sm-8">
                                                            <label className="form-label" for="address">Address</label>
                                                            <textarea className="form-control" id="address" placeholder="Address" rows="4" required></textarea>
                                                            <div className="invalid-feedback"> Please enter Address. </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="row">
                                                        <div className="mb-3 offset-sm-2 col-sm-8">
                                                            <div className="form-check form-switch" style={{ border: 'none' }}>
                                                                <input type="checkbox" className="form-check-input" id="show_gpay" checked={showGpay} onChange={() => setShowGpay(!showGpay)} />
                                                                <label className="form-check-label" for="show_gpay" style={{ padding: 0, fontSize: '14px', fontWeight: 100 }}>Show GPay</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row d-none">
                                                        <div className="mb-3 offset-sm-2 col-sm-8">
                                                            <div className="form-check form-switch">
                                                                <input type="checkbox" className="form-check-input" id="pay_type" />
                                                                <label className="form-check-label" for="pay_type">Use Common Payment System</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pay_type_2 d-none">
                                                        <div className="mb-3 offset-sm-2 col-sm-8">
                                                            <label className="form-label" for="payment_script">Payment Script</label>
                                                            <input type="text" className="form-control" id="payment_script" placeholder="Payment Script" required />
                                                            <div className="invalid-feedback"> Please enter Payment Script. </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="row">
                                                        <div className="mb-3 offset-sm-2 col-sm-8" style={{ textAlign: 'left' }}>
                                                            <label for="tb_password" className="form-label" style={{ fontSize: '14px', fontWeight: 100 }}>Password</label>
                                                            <div className="input-group input-group-merge">
                                                                <input type={isVisible ? 'text' : 'password'} style={{ fontSize: '14px' }} value={password} onChange={(e) => setPassword(e.target.value)} id="tb_password" className="form-control" placeholder="Password" autoComplete={false} />
                                                                <div className="input-group-text" data-password="false" onClick={() => setIsVisible(!isVisible)}>
                                                                    <span className="password-eye"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row d-none">
                                                        <div className="mb-3 offset-sm-2 col-sm-8">
                                                            <label for="allowed_ip" className="form-label">IP Allowed</label>
                                                            <input type="text" id="allowed_ip" className="form-control" placeholder="IP Allowed" />
                                                        </div>
                                                    </div> */}
                                                    <div className="row pay_type_1">
                                                        <div className="mb-3 offset-sm-2 col-sm-8" style={{ textAlign: 'left' }}>
                                                            <label className="form-label" for="upi" style={{ fontSize: '14px', fontWeight: 100 }}>UPI ID</label>
                                                            <input type="text" value={upiId} onChange={(e) => setUpiId(e.target.value)} style={{ fontSize: '14px' }} className="form-control" id="upi" placeholder="UPI ID" required autoComplete={false} />
                                                            <div className="invalid-feedback"> Please enter UPI ID. </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="mb-3 offset-sm-2 col-sm-8" style={{ textAlign: 'left' }}>
                                                            <label className="form-label" for="pixel" style={{ fontSize: '14px', fontWeight: 100 }}>Pixel Code</label>
                                                            <textarea className="form-control" value={pixelCode} style={{ fontSize: '14px' }} onChange={(e) => setPixelCode(e.target.value)} id="pixel" placeholder="Pixel Code" rows="4"></textarea>
                                                            <div className="invalid-feedback"> Please enter Address. </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row otp-div" style={{display: 'none'}}>
                                                        <div className="mb-3 offset-sm-2 col-sm-8">
                                                            <label className="form-label" for="otp">OTP</label>
                                                            <input type="text" className="form-control" id="otp" placeholder="OTP" required />
                                                            <div className="invalid-feedback"> Please enter OTP. </div>
                                                        </div>
                                                    </div>
                                                    <div className="row btn-send-otp" style={{display: 'none'}}>
                                                        <div className="col-sm-12 text-end">
                                                            <button className="btn btn-primary offset-sm-3" type="button" onclick="sendotp()">Send OTP</button>
                                                        </div>
                                                    </div> */}
                                                    <div className="row otp-div" >
                                                        <div className="col-sm-12 text-end">
                                                            <button className="btn btn-primary offset-sm-3" type="submit">Submit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div> 
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Setting